import React, {useEffect, useState, useRef} from "react";
import gsap, {Back} from "gsap";
import isMobile from "ismobilejs";

import AnswerText from "./AnswerText";
import {LazyImage} from "react-lazy-images";

import winnerImage from "../../images/winner-image.png"
import looserImage from "../../images/looser-image.png";

import styles from "./Answer.module.scss";

export default function Answer({correct = true, questionIndex, children}) {
    const section = useRef(null);
    const disclaimer = useRef(null);
    const image = useRef(null);

    const [showText, setShowText] = useState(false);

    useEffect(() => {
        gsap.set(image.current, {
            x: -window.innerWidth,
            onComplete: () => {
                gsap.to([image.current, disclaimer.current], .4, {
                    opacity: 1,
                })
            }
        });

        setTimeout(() => {
            gsap.to(disclaimer.current, .4, {
                x: !isMobile().phone ? -(disclaimer.current.getBoundingClientRect().left - section.current.getBoundingClientRect().left - parseFloat(getComputedStyle(section.current).paddingLeft.replace('px', ''))) : null,
                color: 'transparent',
                webkitTextStrokeWidth: 2,
                onComplete: () => {
                    gsap.to(image.current, .8, {
                        x: -(isMobile().phone ? (correct ? -100 : -170) : window.innerWidth / (correct ? 3.7 : 3.5)),
                        ease: Back.easeInOut.config(isMobile().phone ? 0 : 3),
                        onComplete: () =>
                            setShowText(() => true)
                    });

                }
            })
        }, 500);
    }, [correct]);

    return (
        <section ref={section} className={styles.Main}>
            <span className={[styles.Disclaimer, !correct ? 'incorrect' : null].join(' ')} ref={disclaimer}>
                { correct ? "Bravo !" : "Bah non !"}
            </span>

            <figure className={styles.Image} ref={image}>
                <LazyImage
                    src={correct ? winnerImage : looserImage}
                    alt="Trade Republic"
                    placeholder={({ imageProps, ref }) => (
                        <img ref={ref} src={correct ? winnerImage : looserImage} alt={imageProps.alt} />
                    )}
                    actual={({ imageProps }) => <img {...imageProps} alt={imageProps.alt} />}
                />
            </figure>

            {
                showText ?
                    <AnswerText {...{questionIndex, correct}} image={image.current} disclaimer={disclaimer.current}>
                        {children}
                    </AnswerText>
                : null
            }
        </section>
    )
}
