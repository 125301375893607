import React from "react";
import DelayLink from "../DelayLink";

import Arrow from "../Pictos/Arrow";

import buttonStyles from "./Buttons.module.scss"
import styles from "./HomeButton.module.scss";

export default function HomeButton() {
    return (
        <DelayLink to={'/question-1'} className={[buttonStyles.Global, styles.Main].join(' ')} label={'Je veux savoir'}>
            <span>
                Je veux savoir
                <Arrow/>
            </span>
        </DelayLink>
    )
}
