import React from "react";

export default function Arrow () {
    const svg = `
<svg width="22px" height="15px" viewBox="0 0 22 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path id="Path-2" d="M8,8.5 L0,8.5 L0,6.5 L8,6.5 L8,0.5 L22,7.5 L8,14.5 L8,8.5 Z" fill="#34DF77" fill-rule="nonzero"/>
    </g>
</svg>`

    return (
        <i dangerouslySetInnerHTML={{__html: svg}}/>
    )
}
