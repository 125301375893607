import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import gsap from "gsap";
import isMobile from "ismobilejs";

import QuestionTitle from "../../../components/Questions/QuestionTitle";
import Select from "../../../components/Questions/Select/Select";
import QuestionsMaster from "../QuestionsMaster";

import styles from "./QuestionOne.module.scss"
import styleButton from "../../../components/Buttons/Buttons.module.scss";
import Answer from "../../../components/Questions/Answer";
import {hideQuestion} from "../../../functions/Animations";
import answers from "../../../functions/Answers";
import Validate from "../../../components/Buttons/Validate";

export default function QuestionOne() {
    const {userAnswers} = useSelector(state => state);
    const dispatch = useDispatch();

    const [showNext, setShowNext] = useState(false);
    const [correct, setCorrect] = useState(null);

    const selectOptions = {
        livret_a: [
            {value: '140', label: '140 ans'},
            {value: '60', label: '60 ans'},
            {value: '10', label: '10 ans'},
        ],
        assurance_vie: [
            {value: '140', label: '140 ans'},
            {value: '60', label: '60 ans'},
            {value: '10', label: '10 ans'},
        ],
        bourse: [
            {value: '140', label: '140 ans'},
            {value: '60', label: '60 ans'},
            {value: '10', label: '10 ans'},
        ]
    };

    useEffect(() => {
        if (userAnswers) {
            if (userAnswers[0]) {
                setShowNext(() => Object.values(userAnswers[0]).length >= 3);
            }
        }
    }, [userAnswers]);

    const ValidateAnswer = () => {
        hideQuestion(
            [document.querySelector(`.${styles.TitleBox}`), document.querySelector(`.${styleButton.Validate}`)],
            () => {
                setTimeout(() => {
                    const c = userAnswers[0][0].value === '140' && userAnswers[0][1].value === '60' && userAnswers[0][2].value === '10';
                    //Set correct State to show the answer frame
                    setCorrect(() => c);
                }, 1000)
            }
        );
    };

    return (
        <QuestionsMaster className={styles.Main} questionIndex={0}>
            <QuestionTitle type={"Complétez la phrase"} className={styles.TitleBox}>
                Les Français doivent attendre environ
                <Select options={selectOptions.livret_a} question={0} answer={0}/>&nbsp; pour voir leur capital doubler
                avec le Livret A, <Select
                options={selectOptions.assurance_vie} question={0} answer={1}/> pour une assurance vie investie en euros
                et <Select
                options={selectOptions.bourse} question={0} answer={2}/> avec un portefeuille boursier diversifié.
            </QuestionTitle>

            <Validate {...{showNext}} ValidateAnswer={() => ValidateAnswer()}/>

            {
                correct !== null ?
                    <Answer correct={correct} questionIndex={1}>
                        <h4>
                            C'est {correct ? 'exact' : 'faux'}, malgré la frilosité de certains petits
                            investisseurs
                            <br/>
                            <span className={correct ? 'text--green' : 'text--orange'}>Christian Hecker, cofondateur de Trade Republic explique :</span>
                        </h4>

                        <p>“Les plans d’investissement programmé sont au cœur de notre offre. Aujourd'hui, 80 % de nos
                            clients en Europe en ont un ou plusieurs. Nous souhaitons continuer à développer des
                            produits
                            qui permettent à des millions d'Européens de mieux épargner et investir, pour bénéficier de
                            rendement plus importants que ceux traditionnellement offerts par leurs produits bancaires.
                            Les
                            Français par exemple doivent en effet attendre environ <strong>140
                                ans pour voir leur capital doubler avec le Livret A</strong> (0,5 % de rendement
                            annuel), <strong>60
                                ans
                                pour une assurance vie investie 100 % en euros</strong> (1,2 %) <strong>mais seulement
                                10 ans avec un
                                portefeuille boursier diversifié</strong> (7 %). L’épargne des Français mérite mieux
                            qu’un livret&nbsp;A.”</p>
                    </Answer>
                    : null
            }
        </QuestionsMaster>
    )
}
