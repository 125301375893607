import React, {useEffect, useRef} from "react";

import styles from "./Navigation.module.scss";
import DelayLink from "../components/DelayLink";
import {useSelector} from "react-redux";

export default function Navigation ({questionIndex}) {
    const {userAnswers} = useSelector(state => state);
    const navigation = useRef(null);

    useEffect(() => {
        const listItems = navigation.current.querySelectorAll('li');

        Object.values(listItems).map((item, index) => {
            if (index < questionIndex) {
                item.classList.add('active');
            }

            return true;
        });

        listItems[questionIndex].classList.add('active');
    }, [questionIndex]);

    return (
        <ul className={styles.Main} ref={navigation}>
            {
                [0, 1, 2, 3, 4].map((item, index) => {
                    return (
                        <li key={index}>
                            {
                                index !== questionIndex ?
                                    userAnswers ?
                                        userAnswers[index > 0 ? index - 1 : 0]  ?
                                            <DelayLink
                                                to={index < 4 ? `/question-${index+1}` : `/conclusion`}
                                                label={''}>
                                                <span/>
                                            </DelayLink>
                                            : <span/>
                                        : <span/>

                                    : <span/>
                            }
                        </li>
                    )
                })
            }
        </ul>
    )
}
