import React from "react";
import DelayLink from "../DelayLink";

import styles from "./NextButton.module.scss";
import buttonStyles from "./Buttons.module.scss";

export default function NextButton ({url = "/", correct = true, delayStart = () => {}, delay}) {
    return (
        <DelayLink to={url} className={[buttonStyles.Global, styles.Main, !correct ? 'orange' : '' ].join(' ')} label={'Je passe à la suite'} onDelayStart={delayStart} delay={delay}>
            <span>Je passe à la suite</span>
        </DelayLink>
    )
}
