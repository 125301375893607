import React, {useEffect, useRef} from "react";
import gsap from "gsap";
import NextButton from "../Buttons/NextButton";

import styles from "./AnswerText.module.scss"
import {hideQuestion} from "../../functions/Animations";

export default function AnswerText ({ correct = true, children, questionIndex, image, disclaimer}) {
    const text = useRef(null);

    useEffect(() => {
        gsap.to(text.current, .8, {
            opacity: 1
        });
    }, []);


    return (
        <article className={styles.Main} id={'answerText'} ref={text}>
            <div className={[styles.TextScroll, correct ? 'green' : 'orange'].join(' ')}>
                { children }
            </div>

            <NextButton
                delayStart={() => hideQuestion([disclaimer, image, text.current])}
                delay={2000}
                correct={correct} url={!isNaN(questionIndex) ? `/question-${questionIndex+1}` : '/conclusion'}/>
        </article>
    )
}
