import React, {useEffect, useState, useRef} from "react";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useDispatch, useSelector} from "react-redux";
import gsap from "gsap";

import QuestionTitle from "../../../components/Questions/QuestionTitle";
import styles from "./QuestionFour.module.scss";
import styleButton from "../../../components/Buttons/Buttons.module.scss";

import QuestionsMaster from "../QuestionsMaster";

import Answer from "../../../components/Questions/Answer";
import {hideQuestion} from "../../../functions/Animations";
import Validate from "../../../components/Buttons/Validate";
import isMobile from "ismobilejs";
import answers from "../../../functions/Answers";

export default function QuestionFour() {
    const {userAnswers} = useSelector(state => state);
    const dispatch = useDispatch();

    const [showNext, setShowNext] = useState(false);
    const [correct, setCorrect] = useState(null);
    const [boxes, setBoxes] = useState([ "régulièrement", "pendant des années", "en 1 seule fois" ]);
    const [words, setWords] = useState([]);

    useEffect(() => {
        const listing = document.getElementsByClassName(styles.WordsListing);
        const words = document.getElementsByClassName(styles.Word);

        gsap.to(listing, .3, {
            opacity: 1,
            delay: 1,
            onComplete: () => {
                Object.values(words).map((item, index) => {
                    gsap.to(item, .3, {
                        opacity: 1,
                        scale: 1,
                        delay: .2 * index
                    })
                })
            }
        })
    }, []);

    useEffect(() => {
        if (userAnswers) {
            if (userAnswers[3]) {
                setWords(() => userAnswers[3][0])
                const boxesClone = [...boxes];

                userAnswers[3][0].map(item => {
                    const index = boxesClone.indexOf(item);
                    boxesClone.splice(index, 1);

                    setBoxes(boxesClone);
                    setShowNext(true);
                })
            }
        }
    }, [userAnswers]);

    const handleOnDragEnd = (result) => {
        const {source, destination} = result;
        const boxesState = [...boxes];
        const wordsState = [...words];

        let destIndex, sourceIndex;

        if (!destination) return;
        else {
            destIndex = parseFloat(destination.droppableId.replace('word', '')) - 1;
            sourceIndex = parseFloat(source.droppableId.replace('word', '')) - 1;
        }

        if (source.droppableId === 'words') {
            if (source.droppableId !== destination.droppableId) {
                if (wordsState[destIndex]) {
                    boxesState.push(wordsState[destIndex]);
                    wordsState.splice(destIndex, wordsState.length);
                }

                wordsState[destIndex] = boxesState[source.index];
                boxesState.splice(source.index, 1);
            }
        } else {
            [wordsState[sourceIndex], wordsState[destIndex]] = [wordsState[destIndex], wordsState[sourceIndex]]
        }

        setWords(() => wordsState);
        setBoxes(() => boxesState);

        if (boxesState.length === 0) {
            setShowNext(true);
        }
    };

    const ValidateAnswer = async () => {
        hideQuestion(
            [document.querySelector(`.${styles.TitleBox}`), document.querySelector(`.${styleButton.Validate}`)],
            () => {
                const c = words[0] === 'régulièrement' && words[1] === 'pendant des années' && words[2] === 'en 1 seule fois';
                setCorrect(() => c);
                answers.validate(words, 3, 0, userAnswers, c, dispatch);
            }
        );
    };

    return (
        <QuestionsMaster className={styles.Main} questionIndex={3}>
            <DragDropContext onDragEnd={handleOnDragEnd} >
                <div className={styles.Content}>
                    <QuestionTitle type={"Donner du sens"} advice={"Replacez les mots au bon endroit"}>
                        Retrouver la citation de <br/> Warren Buffet, célèbre <br/> milliardaire américain
                    </QuestionTitle>
                    {
                        boxes.length > 0 ?
                            <Droppable droppableId={'words'} direction={isMobile().phone ? 'vertical' : 'grid'}>
                                {provided => (
                                    <div className={styles.WordsListing} {...provided.droppableProps} ref={provided.innerRef}>
                                        {
                                            boxes ? boxes.map((item, index) =>
                                                <Draggable key={index} draggableId={`words-${index.toString()}`} index={index}>
                                                    {(provided) => (
                                                        <span key={index}
                                                            className={styles.Word}
                                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            {item}
                                                        </span>
                                                    )}
                                                </Draggable>) : null

                                        }
                                        {provided.placeholder}
                                    </div>
                                )}

                            </Droppable>
                            : null
                    }
                </div>

                <div className={styles.Sentence}>
                    <span className={styles.Word}>En achetant</span>
                    <Droppable droppableId={'word1'} direction={'horizontal'}>
                        {(provided) => (
                            <div className={styles.Hole} {...provided.droppableProps} ref={provided.innerRef}>
                                {
                                    words[0] ?
                                        <Draggable draggableId={`word1`} index={0}>
                                            {(provided) => (
                                                <span className={styles.Word}
                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    {words[0]}
                                            </span>
                                            )}
                                        </Draggable>
                                        : null
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <span className={styles.Word}>sur le marché</span>
                    <Droppable droppableId={'word2'} direction={'horizontal'}>
                        {(provided) => (
                            <div className={styles.Hole} {...provided.droppableProps} ref={provided.innerRef}>
                                {
                                    words[1] ?
                                        <Draggable draggableId={`word2`} index={1}>
                                            {(provided) => (
                                                <span className={styles.Word}
                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    {words[1]}
                                            </span>
                                            )}
                                        </Draggable>
                                        : null
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <span className={styles.Word}>vous aurez</span> <span className={styles.Word}>une meilleure performance</span> <span className={styles.Word}>que 90% des gens</span> <span className={styles.Word}>qui investissement</span>
                    <Droppable droppableId={'word3'}>
                        {(provided) => (
                            <div className={styles.Hole} {...provided.droppableProps} ref={provided.innerRef}>
                                {
                                    words[2] ?
                                        <Draggable draggableId={`word3`} index={2} direction={'horizontal'}>
                                            {(provided) => (
                                                <span className={styles.Word}
                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    {words[2]}
                                            </span>
                                            )}
                                        </Draggable>
                                        : null
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>


           <Validate {...{showNext, ValidateAnswer}}/>

            {
                correct !== null ?
                    <Answer correct={correct}>
                        <h4>
                            Outre son ticket d'entrée très accessible, <span className={correct ? 'text--green' : 'text--orange'}>l'investissement programmé est un dispositif idéal pour démarrer en bourse</span>
                        </h4>

                        <p>
                            Il vous permet d'investir sans être obligé de suivre heure par heure les variations des cours de bourse et est particulièrement efficace, puisqu'un investissement régulier de long terme vous permet de lisser les tendances de court terme en passant outre les "à-coups" des marchés boursiers.
                            <br/><br/>
                            Le célèbre milliardaire américain Warren Buffett qui a fait fortune en bourse aime le répéter aux investisseurs débutants : "En achetant régulièrement sur le marché pendant des années, vous aurez une meilleure performance que 90% des gens qui investissent en une seule fois " !
                        </p>
                    </Answer>
                    : null
            }
        </QuestionsMaster>
    )
}
