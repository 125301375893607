import React from "react";

import styles from "./Header.module.scss";

export default function Header ({children, container = true}) {
    return (
        <header className={styles.Main}>
            <div className={container ? styles.Container : ''}>
                {children}
            </div>
        </header>
    )
}
