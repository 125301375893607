import React from "react"

export default function Info () {
    const svg = `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="21" height="21" x="0px" y="0px"
	 viewBox="0 0 49.2 49.2" style="enable-background:new 0 0 49.2 49.2;" xml:space="preserve">
        <path d="M24.6,13c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7c0,0,0,0,0,0C27.3,14.1,26.1,13,24.6,13z" fill="#ffffff"/>
        <path d="M24.6,21.9c-1,0-1.8,0.8-1.8,1.7c0,0,0,0,0,0.1v12.7c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8l0,0V23.6
            C26.4,22.7,25.6,21.9,24.6,21.9z" fill="#ffffff"/>
        <path d="M24.6,0C11,0,0,11,0,24.6s11,24.6,24.6,24.6s24.6-11,24.6-24.6C49.2,11,38.2,0,24.6,0z M24.6,45.7
            C13,45.7,3.5,36.3,3.5,24.6C3.5,13,13,3.5,24.6,3.5c11.7,0,21.1,9.5,21.1,21.1C45.7,36.3,36.3,45.7,24.6,45.7z" fill="#ffffff"/>
</svg>
`
    return (
        <i dangerouslySetInnerHTML={{__html: svg}}/>
    )
}
