import StoryblokClient from 'storyblok-js-client/dist/es5/index.es'


const answers = {
    validate: (answerSelected, question, answer, answers, correct, dispatch) => {
        const answersClone = {...answers};

        if (answerSelected) {
            answersClone[question] = {...answersClone[question],
                [answer]: answerSelected,
            };
        }

        if (correct !== null) {
            answersClone[question] = {...answersClone[question],
                correct: correct
            };
        }


        if (answerSelected) {
            localStorage.setItem('answers', JSON.stringify(answersClone));
            dispatch({ type: 'UPDATE_USER_ANSWERS', payload: answersClone })
        }
        console.log(question, Object.values(answersClone).length);

        if (question === 3 && Object.values(answersClone).length === 4) {

            const Storyblok = new StoryblokClient({
                oauthToken: 'HF6oYK4ewN5fgDdXbPANWAtt-97153-rgkRrQXikA5nm91xhxXK'
            });
            const name = '_' + Math.random().toString(36).substr(2, 9);

            Storyblok.post('spaces/128890/stories/', {
                story: {
                    name: 'answer-'+name,
                    slug: 'answer-'+name,
                    content: {
                        component: "Answer",
                        "select_1": answersClone[0][0].value,
                        "select_2": answersClone[0][1].value,
                        "select_3": answersClone[0][2].value,
                        "correct_1": answersClone[0].correct ?? (answersClone[0][0].value === '140' && answersClone[0][1].value === '60' && answersClone[0][2].value === '10'),
                        "boolean_2": answersClone[1][0],
                        "correct_2": answersClone[1].correct,
                        "for_1000": answersClone[2][0][0].coin,
                        "for_5000": answersClone[2][0][1].coin,
                        "for_10000": answersClone[2][0][2].coin,
                        "correct_3": answersClone[2].correct,
                        "words_4": answersClone[3][0].join(', '),
                        "correct_4": answersClone[3].correct
                    },
                    publish: 1,
                    disble_fe_editor: true
        }
            })
                .then(resp => console.log(resp))
                .catch(e => console.error(e))
        }
    }
}

export default answers;
