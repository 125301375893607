import { createStore } from 'redux';

const initialState = {
    stateInfo: null,
    userAnswers: null
}

const reducer = (state, {type, payload}) => {
    switch (type) {
        case 'UPDATE_USER_ANSWERS':
            return { ...state, userAnswers: payload };
        default:
            return state
    }
};

const store = createStore(
    reducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
