import React, {useEffect, useState, useRef} from "react";
import Chevron from "../../Pictos/Chevron";

import styles from "./Select.module.scss";
import {useDispatch, useSelector} from "react-redux";
import answers from "../../../functions/Answers";

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}


export default function Select({options, question, answer}) {
    const dispatch = useDispatch();
    const {userAnswers} = useSelector(state => state);

    const [answerSelected, setAnswer] = useState(null);
    const previousSelected = usePrevious(answerSelected);

    const [open, setOpen] = useState(false);
    const select = useRef(Math.random().toString(36).substr(2, 9));
    const firstSet = useRef(false);

    useEffect(() => {
        window.addEventListener('click', (e) => {
            if (!e.target.closest(`[data-id="${select.current}"]`)) {
                setOpen(() => false)
            }
        })
    }, []);

    useEffect(() => {
        if (userAnswers && !firstSet.current) {
                console.log(userAnswers, answer)
            if (userAnswers[question]) {
                setAnswer(options.find(item => item.value === userAnswers[question][answer]?.value))
                firstSet.current = true;
            }
        }
    }, [userAnswers]);

    useEffect(() => {
        if (answerSelected !== previousSelected) {
            answers.validate(answerSelected ?? null, question, answer, userAnswers, null, dispatch);
        }

    }, [userAnswers, answerSelected, previousSelected, question, answer])

    return (
        <div className={styles.Main} data-id={select.current}>
            <span onClick={() => setOpen((prev) => !prev)}
                  style={{justifyContent: answerSelected?.value ? 'space-between' : 'flex-end'}}>
                {answerSelected?.label} <Chevron/>
            </span>

            {
                open ?
                    <ul className={styles.Options}>
                        {
                            options.map(item =>
                                <li
                                    className={item.value === answerSelected?.value ? styles.Active : ''}
                                    onClick={() => {
                                        setOpen(false);
                                        setAnswer(item);

                                        return item.value;
                                    }}
                                    data-value={item.value} key={item.value}>
                                    {item.label}
                                </li>
                            )
                        }
                    </ul> : null
            }
        </div>
    )
}
