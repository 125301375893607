import gsap from "gsap";

export function hideQuestion (elements, callback = () => {}) {
    elements.map((item, index) =>
        gsap.to(item, .3, {
            opacity: 0,
            delay: index * .2,
            onComplete: () => elements.splice(0, 1)
        })
    );

    const interval = setInterval(() => {
        if (elements.length === 0) {
            callback();
            clearInterval(interval);
        }
    })
}
