import React, {useEffect, useRef} from "react";
import {LazyImage} from "react-lazy-images";
import gsap from "gsap";

import Social from "../partials/Social";
import Master from "../partials/Master";
import Header from "../partials/Header";

import Logo from "../images/svg/logo-trade-republic-white.svg";

import styles from "./Home.module.scss";
import HomeButton from "../components/Buttons/HomeButton";
import isMobile from "ismobilejs";

export default function     App() {
    const firstLine = useRef(null)
    const secondLine = useRef(null);
    const buttonBox = useRef(null);

    useEffect(() => {
        gsap.to(firstLine.current, .3, {
            opacity: 1,
            textIndent: isMobile().phone ? -40 : -80,
            delay: .5
        })

        gsap.to(secondLine.current, .3, {
            opacity: 1,
            textIndent: isMobile().phone ? 40 : 80,
            delay: .5,

            onComplete: () => {
                gsap.to(buttonBox.current, .3, {
                    opacity: 1
                })
            }
        })
    }, []);

    return (
        <Master container={true}>
            <Header>
                <figure className={styles.Logo}>
                    <LazyImage
                        src={Logo}
                        alt="Trade Republic"
                        placeholder={({imageProps, ref}) => (
                            <img ref={ref} src={Logo} alt={imageProps.alt}/>
                        )}
                        actual={({imageProps}) => <img {...imageProps} alt={imageProps.alt}/>}
                    />
                </figure>

                <Social/>
            </Header>

            <section className={styles.Main}>
                <div>
                    <h1 className={styles.Title} >
                       <span ref={firstLine}>
                           <span className={"textStroke"} data-text="Mon">Mon</span> <span  data-text="épargne">épargne</span>
                       </span>
                        <span ref={secondLine}>
                           <span  data-text="mérite">mérite</span> <span className={"textStroke--green"} data-text="mieux" >mieux</span>
                       </span>
                    </h1>

                    <div className={styles.ButtonBox} ref={buttonBox}>
                        <p>
                            Si, si on le pense vraiment <br/>
                            et on va vous expliquer pourquoi
                        </p>

                        <HomeButton/>
                    </div>
                </div>
            </section>
        </Master>
    )
}
