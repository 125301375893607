import React from "react";

import styles from "./Master.module.scss";

export default function Master ({children}) {
    return (
        <main className={styles.Main}>
            {children}
        </main>
    )
}
