import React, {useEffect, useRef} from "react";
import Content from "../partials/Content";
import Header from "../partials/Header";
import Navigation from "../partials/Navigation";
import Social from "../partials/Social";
import isMobile from 'ismobilejs';

import styles from "./Conclusion.module.scss";

import Arrow from "../components/Pictos/Arrow";
import gsap from "gsap";
import Button from "../components/Buttons/Button";

export default function Conclusion() {
    const firstLine = useRef(null);
    const secondLine = useRef(null);

    useEffect(() => {
        gsap.to(firstLine.current, .3, {
            opacity: 1,
            textIndent: -80,
            delay: .5
        });

        gsap.to(secondLine.current, .3, {
            opacity: 1,
            textIndent: 80,
            delay: .5
        });

        gsap.to('hr', 1, {
            opacity: 1,
            left: 0
        })

        const listItems = document.querySelectorAll(`.${styles.Advices} li`);
        Object.values(listItems).map((item, index) => {
            gsap.set(item, {
                x: -5,
            })
            gsap.to(item, .3, {
                opacity: 1,
                x: 0,
                delay: .2 * index

            })
        })
    }, []);


    return (
        <Content container={true}>
            <Header>
                <Navigation questionIndex={4}/>

                {
                    !isMobile().phone ? <Social/> : null
                }
            </Header>

            <div className={styles.Main}>
                <div className={styles.TitleWrapper}>
                    <h2 className={styles.Title}>En résumé</h2>

                    <ul className={styles.Advices}>
                        <li>
                            <Arrow/> <p>Investir dans un <span>portefeuille boursier diversifié</span></p>
                        </li>
                        <li>
                            <Arrow/> <p>Investir sur <span>le long terme</span> pour limiter les risques</p>
                        </li>
                        <li>
                            <Arrow/> <p><span>Réduire les frais</span> qui peuvent impacter votre épargne</p>
                        </li>
                        <li>
                            <Arrow/> <p>Opter pour une routine qui rapporte
                            : <span>l’investissement programmé</span></p>
                        </li>
                    </ul>

                    <hr/>
                </div>

                <div className={styles.Promise}>
                    <h1>
                        <small>
                            Si vous pensez que
                        </small>
                        <span ref={firstLine}>
                           <span className={"textStroke"} data-text="votre">votre</span> <span
                            data-text="épargne">épargne</span>
                       </span>
                        <span ref={secondLine}>
                           <span data-text="mérite">mérite</span> <span className={"textStroke"}
                                                                        data-text="mieux">mieux</span>
                       </span>
                    </h1>

                    <Button external={true} url={'https://bit.ly/3Bow07j'}/>
                </div>
            </div>
        </Content>
    )
}
