import React from "react";
import Content from "../../partials/Content";
import Header from "../../partials/Header";
import Navigation from "../../partials/Navigation";

export default function QuestionsMaster ({className, children, questionIndex}) {
    return (
        <Content container={true} className={className}>
            <Header>
                <Navigation questionIndex={questionIndex}/>
            </Header>

            {children}
        </Content>
    )
}
