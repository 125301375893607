import React, {useEffect} from "react";
import gsap from "gsap";
import isMobile from "ismobilejs";


export default function Plan({cursors}) {
    useEffect(() => {
        const plan = document.getElementById('Plan');
        const planPath = document.querySelector('.PlanPath');
        const planPoints = document.querySelectorAll('.PlanPoint');
        const planPointsSmall = document.querySelectorAll('.PlanPointSmall')
        const planButtons = document.querySelectorAll('.PlanButton');

        gsap.to(plan, .4, {
            opacity: 1,
            delay: 1.5,
            onComplete: () => {
                gsap.to(planButtons, .4, {
                    opacity: 1,
                    y: 0,
                    onComplete: () => {
                        gsap.to(planPath, 6, {
                            strokeDashoffset: 0,
                            delay: .5
                        })
                    }
                });

                gsap.to(['.PlanPoint', '.PlanPointSmall'], .4, {
                    opacity: 1
                });

                gsap.to('.PlanPoint', .4, {
                    scale: 1,
                    x: -13,
                    y: -13,
                    onComplete: () => console.log('ok')
                });

                gsap.to('.PlanPointSmall', .4, {
                    scale: 1,
                    x: -3,
                    y: -3,
                    force3D: false,
                })
            }
        })

        Object.values(planButtons).map((el, index) => {
            const whichButton = el.id.replace('Question2-', '');
            const point = document.querySelectorAll(`#PlanPoint${whichButton} .PlanPoint`)

            el.addEventListener('mouseenter', () => {
               gsap.to([point, planPath], .3, {
                   stroke: '#E28B61'
               })
            });

            el.addEventListener('mouseleave', () => {
                gsap.to([point, planPath], .3, {
                    stroke: '#34DF77'
                })
            })
        })
    }, [])

    const svg = `
<svg id="Plan" width="883px" height="800px" viewBox="0 0 883 800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <rect id="path-1" x="0" y="-4.54747351e-13" width="883" height="800"/>
        <filter x="-8.1%" y="-17.7%" width="116.2%" height="135.5%" filterUnits="objectBoundingBox" id="filter-3">
            <feGaussianBlur stdDeviation="50" in="SourceGraphic"/>
        </filter>
        <filter x="-8.1%" y="-17.7%" width="116.2%" height="135.5%" filterUnits="objectBoundingBox" id="filter-4">
            <feGaussianBlur stdDeviation="50" in="SourceGraphic"/>
        </filter>
    </defs>
    ${!cursors ? `
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g>
            <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"/>
            </mask>
            <use id="Mask" fill="transparent" opacity="0.899999976" xlink:href="#path-1"/>
            <g id="plan-" mask="url(#mask-2)">
                <g transform="translate(-901.000000, -454.000000)"/>
            </g>
            <g id="Group-47" opacity="0.3" mask="url(#mask-2)" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.5">
                <g transform="translate(471.500000, 436.000000) rotate(-33.000000) translate(-471.500000, -436.000000) translate(-121.000000, 74.000000)">
                    <path d="M249.3324,31.264191 L341.68001,59.9462064 L315.295216,192.945774 L291.733311,210.952813 L232.68184,213.498729 L232.654989,213.499835 L177.265136,238.514437 L131.841204,242.22224 L88.2732711,254.265754 L88.2367324,254.27599 L70.5976902,272.600057 L70.544543,272.655109 L81.6509142,317.747398 L69.2842358,319.807258 L69.2554477,319.811961 L27.7483507,346.925428 L0,381.637361 L69.276762,396.971783 L69.4057545,397 L73.6118535,381.587013 L73.6160057,381.570691 L74.0796592,359.567314 L78.9456687,341.269251 L82.0611438,317.928873 L81.9781014,317.917808 L70.8501392,272.736994 L88.3834407,254.522476 L131.889369,242.493624 L177.311917,238.788033 L177.335722,238.786097 L232.720316,213.773709 L291.788949,211.227239 L291.832408,211.225579 L315.50476,193.133889 L315.546558,193.102076 L341.975641,59.8778764 L342,59.7558782 L249.414612,31 L249.3324,31.264191 Z M0.493548834,381.463632 L27.9185877,347.14508 L69.3581435,320.075045 L81.7716026,318.007716 L78.6746736,341.215306 L73.8078337,359.512539 L73.8034047,359.528308 L73.3403048,381.530579 L69.2078367,396.673012 L0.493548834,381.463632 Z" id="Fill-1"/>
                    <polygon id="Fill-3" points="343.960359 536 65 496.270005 65.0393635 496 344 535.729995"/>
                    <polygon id="Fill-4" points="349.957953 504 81 463.270305 81.0420473 463 350 503.729968"/>
                    <polygon id="Fill-5" points="353.959666 470 96 431.276681 96.0406098 431 354 469.723319"/>
                    <polygon id="Fill-6" points="357.958436 437 102 398.272319 102.041841 398 358 436.727956"/>
                    <polygon id="Fill-7" points="343.352043 401 343.329329 400.996427 74 359.271546 74.0426594 359 343.349273 400.721308 360.952354 397.657623 361 397.928069"/>
                    <polygon id="Fill-8" points="362.957054 359 161 327.174293 161.033526 327.028927 166.136889 305 166.406756 305.063094 161.336918 326.946377 363 358.725389"/>
                    <path d="M367.659192,321.870589 L537.833528,337.870339 L564.088003,339.707537 L570.67657,299.982434 L544.447527,298.605844 L373.487838,280.450798 L204.518208,252.318374 L199.310714,288.665679 L367.659192,321.870589 Z M564.319656,340 L564.194154,339.991179 L537.81086,338.14516 L367.619109,322.143481 L367.612474,322.142103 L199,288.885371 L199.018245,288.756092 L204.284344,252 L204.423944,252.023154 L373.525156,280.177906 L544.469365,298.331023 L571,299.723324 L570.974568,299.875206 L564.319656,340 Z" id="Fill-9"/>
                    <polygon id="Fill-10" points="522.330765 422 361 407.611429 361.024322 407.335552 522.106335 421.701964 538.126257 338.033757 551.674964 257.80211 478.247995 255.025329 379.507721 243.275047 379.540059 243 478.269278 254.749452 552 257.537312 551.973743 257.692701 538.398503 338.082783"/>
                    <polygon id="Fill-11" points="599.829377 386 599.704895 385.990017 458.433085 374.691946 362.086085 363.857788 362.116791 363.582157 458.459365 374.416038 599.602544 385.70385 625.544759 243.912831 625.688051 243.949711 715.546247 266.855314 773.12612 283.567553 790.714246 284.793748 789.178974 233.083941 718.759252 235.400734 629.192066 226.887804 484.058841 211.10145 384.937564 200.732037 369.166868 198.717218 369.149164 198.709453 347.256192 189.282008 315.021577 191.833114 315 191.556652 347.302665 189 347.333924 189.013587 369.24045 198.446856 384.969376 200.456406 484.088163 210.825543 629.220006 226.612174 718.767551 235.123994 789.447578 232.797496 789.45145 232.936698 791 285.091839 773.063326 283.837915 715.474048 267.122903 625.76357 244.254735"/>
                    <polygon id="Fill-12" points="379.956892 239 287.264369 224.177641 249.304319 223.710799 249.271159 223.689274 233 213.235657 233.148113 213 249.386112 223.432371 287.287581 223.899772 380 238.724088"/>
                    <path d="M270.631711,138.960793 L340.016253,148.358026 L340.029541,148.362728 L372.014312,159.632547 L391.472602,162.385554 L396.690769,122.980187 L373.004847,118.984689 L372.985191,118.974179 L354.307868,109.103959 L283.681973,96.315878 L270.631711,138.960793 Z M241.887359,584 L241.826177,583.981468 L51,526.195983 L51.069487,526.051044 L96.0258974,431.893672 L102.64294,398.375474 L83.5666894,392.073403 L83.5428811,392.048786 L73.3449065,381.473788 L73.5445085,381.281827 L83.7186748,391.832484 L102.962137,398.189598 L102.938329,398.309367 L96.2908338,431.981354 L96.2833591,431.997121 L51.3889609,526.024767 L241.845279,583.698229 L274.595514,562.706724 L274.650605,562.717788 L336.475739,575.040903 L350.045631,507.402901 L358.696898,440.565104 L362.868331,358.599462 L385.273866,201.735325 L391.432737,162.659112 L371.948977,159.90002 L339.950918,148.628541 L270.271541,139.191201 L283.489016,96 L283.609995,96.0218515 L354.399226,108.839529 L373.094543,118.719429 L397,122.751715 L396.982559,122.88393 L391.746674,162.424278 L391.750273,162.424831 L385.547938,201.776262 L363.143511,358.626015 L358.972631,440.589998 L350.318873,507.447987 L336.692505,575.366185 L274.651159,562.999921 L241.887359,584 Z" id="Fill-13"/>
                    <polygon id="Fill-14" points="596.020944 175 595.746817 174.957045 596.548942 169.847674 585.929597 169.129364 562.992598 161.595972 562.977061 161.585718 550.66135 153.41937 544.216328 140.856419 544.236582 140.799886 547.891508 130.682848 567.496539 135.057559 570.181757 143.205616 588.323208 147.710021 609.496142 134.261653 622.350117 90.3028249 601.40331 82.7422745 609.771933 75.0184969 625.589137 76.3523042 626.710614 56.1888966 617.499911 53.6335181 617.477992 53.6163363 594.208879 35.3329513 594.199168 35.2825143 592.562734 27.0080875 532.994626 6.35361272 507.332734 82.1664068 504.703562 93.2905198 497.271074 127.007604 497 126.948022 504.433042 93.2289979 507.066376 82.0901971 532.821216 6 592.803843 26.7983031 594.461364 35.1785921 617.627819 53.3813335 627 55.9816064 625.8505 76.6524316 609.87043 75.304768 601.926869 82.6364124 622.689168 90.1307297 622.652544 90.2557135 609.73309 134.439568 609.689252 134.467557 588.371763 148.007655 588.313775 147.993244 569.966174 143.43757 567.282621 135.29367 548.069912 131.006531 544.5182 140.837298 550.875269 153.22843 563.114679 161.344341 585.999793 168.860552 596.870513 169.591333"/>
                    <path d="M937.015034,129.911272 L959.557558,130.021096 L958.924517,68.1217908 L935.124207,68.4399211 L937.015034,129.911272 Z M959.834116,130.022479 L1066.3629,130.542276 L1067.28439,113.036534 L1067.3032,113.00638 L1096.49666,66.2835509 L959.201075,68.1179179 L959.834116,130.022479 Z M937.023607,130.187907 L938.824553,188.731628 L938.84087,189.706214 L960.156583,188.604653 L959.560324,130.297731 L937.023607,130.187907 Z M820.893836,192.920713 L876.634389,192.920713 L938.564589,189.720599 L938.547995,188.738268 L936.746773,130.186523 L876.633836,129.89329 L820.279047,132.049384 L820.893836,192.920713 Z M942.219857,345 L941.943852,344.978699 L944.10543,316.877834 L939.474465,244.32891 L938.569014,189.997234 L876.641303,193.197348 L820.620043,193.197348 L820.61866,193.060414 L820,131.783538 L876.628858,129.616655 L936.738476,129.909888 L934.839075,68.1668823 L1097,66 L1096.86559,66.2152221 L1067.55652,113.122567 L1066.62535,130.820294 L1066.49371,130.819741 L959.836882,130.299114 L960.435906,188.867179 L938.845295,189.982849 L939.750746,244.317845 L944.381158,316.879494 L942.219857,345 Z" id="Fill-31"/>
                    <polygon id="Fill-15" points="401.221859 113 400.946036 112.987259 402.796927 72.3291213 400 18 431.729088 53.2276352 450.683241 82.8757519 505 91.9239117 504.954674 92.1972926 450.516863 83.1289131 450.483698 83.0771175 431.510198 53.3949321 400.316451 18.7641924 403.07275 72.3282903"/>
                    <polygon id="Fill-16" points="536.914483 622 536.801591 621.951844 423.808526 574.022892 423.827341 573.914955 466.142603 331.549632 484.056515 211.683524 490.9839 166.566466 392 156.275377 392.0285 156 491.301548 166.321256 491.279412 166.464895 484.329892 211.725038 466.415426 331.593914 424.118703 573.854344 536.79661 621.649067 562.205136 577.289227 579.960778 473.045736 599.730497 386.225469 600 386.28691 580.232218 473.099704 562.469382 577.384433 562.457207 577.405467"/>
                    <polygon id="Fill-17" points="496.671064 493 496.542953 492.983613 354 475.000865 354.034662 474.725335 496.449504 492.691974 503.668908 456.226803 358.954727 440.276085 358.985229 440 504 455.98377 503.970329 456.132645"/>
                    <polygon id="Fill-18" points="425.963947 556 361.877234 547.752824 361.850263 547.734833 355.111362 543.251825 343 540.2602 343.067703 540 355.226402 543.00344 355.246217 543.016598 361.978238 547.494772 426 555.733624"/>
                    <polygon id="Fill-19" points="499.893759 572 427.19033 554.014651 427.256038 553.745381 499.707679 571.668612 511.640264 533.406761 432.903592 521.203922 350 507.273707 350.045278 507 432.947766 520.930215 512 533.182139"/>
                    <polygon id="Fill-20" points="616.289494 497 587.581263 487.043895 580 471.533632 580.24838 471.411764 587.779359 486.819269 616.127038 496.650459 633.95461 449.968165 632.104328 436.21364 632.113722 436.184003 672.251867 310.67299 754.872436 341.791288 808.621213 348.872681 790.789774 285.074506 791.055836 285 809 349.20228 754.790103 342.056075 672.426203 311.034441 632.386692 436.238567 634.237802 450.001125 634.225093 450.034362"/>
                    <polygon id="Fill-21" points="701.240643 322 700.974912 321.9201 706.659731 302.643808 672.876124 290.214499 664.520139 307.748978 664.396556 307.691787 618 286.255118 618.11527 286 664.38852 307.380038 672.735362 289.864342 672.853126 289.907516 707 302.470832"/>
                    <polygon id="Fill-22" points="707.275487 302 707 301.929586 716.38164 266.823017 719.716822 235 720 235.028388 716.66083 266.872557"/>
                    <polygon id="Fill-23" points="755.393893 342.053121 690.801026 519.01195 622.405734 723.610748 581.344972 703.200029 665.626368 473.705118 712.334835 342.766059 717.730648 328.096746 717.471574 328.0013 712.075485 342.67172 665.366465 473.611056 581.042811 703.221055 581 703.33725 622.425068 723.929453 622.567034 724 691.062033 519.103245 755.655453 342.142481 774 284.08355 773.736783 284"/>
                    <polygon id="Fill-24" points="764.562003 586 764.304335 585.897952 853.628737 361.916447 809.053672 349.33546 737.605566 538.279806 737.473961 538.226984 690 519.08697 690.103898 518.830328 737.446532 537.91752 808.884388 349 809.006572 349.034569 854 361.733645 853.943202 361.87607"/>
                    <polygon id="Fill-25" points="863.827686 587 863.739393 586.970925 812 569.923186 812.058124 569.784733 862.170602 450.636367 862.305948 450.712793 906.355551 475.675899 916.489312 453.249255 939.341964 383.421784 942.111706 345.549858 836.57644 332.274968 836.611038 332 942.406753 345.308119 942.397343 345.439095 939.615423 383.47578 939.610441 383.491564 916.746995 453.349495 906.483147 476.066337 906.351399 475.991572 862.305117 451.030681 812.371439 569.753996 863.737456 586.678789 897.333616 547.208836 907.52135 507.05631 918.082737 478.151996 918.22085 478.219284 971.954723 504.480527 1005.95775 506.026772 1005.9929 506.05557 1054.80922 545.620778 1084.00568 564.375645 1125.3456 457.583947 1137.66648 432.621394 1104.71992 397.811775 1081.30568 399.969153 1069.55165 401.206924 1069.5367 401.205263 1002.18247 393.477914 1002.21374 393.202669 1069.55276 400.928356 1081.27856 399.693631 1104.82842 397.523516 1104.87464 397.572528 1138 432.571274 1125.59858 457.695263 1084.1413 564.792112 1054.6473 545.845073 1005.85396 506.299248 971.884975 504.754664 971.858957 504.741927 918.237457 478.535511 907.785398 507.137997 897.58604 547.338982 897.565282 547.363627"/>
                    <polygon id="Fill-26" points="854.431467 362 853 334.013896 853.272115 334 854.689132 361.703548 871.489923 360.204395 875.731975 337.798291 876 337.848699 871.719504 360.457796 871.616983 360.466787"/>
                    <polygon id="Fill-27" points="1119.24882 247.9498 1119.17405 247.98193 1117.01121 268.994065 1042.31208 289.987643 791.008585 281.949347 791 282.226329 1042.32509 290.265179 1042.34614 290.26601 1117.17654 269.235594 1117.26765 269.209834 1119.43271 248.172493 1181.99473 221.355091 1184.67931 226.426909 1168.9576 250.784434 1168.94763 250.799391 1142.69446 330.192419 1123.61798 332.038227 1123.59057 332.040997 1107.83894 340.382035 1087.00949 337.894736 1087.6276 354.901988 1025.95979 348.425593 1025.91852 348.421439 1016.95672 353.057841 993.815714 349.663149 993.64097 349.637667 996.13059 375.476215 1002.09959 385.870243 1013.00102 377.770457 1064.84217 383.326163 1077.15652 392.870965 1081.46337 399.948133 1097.08733 445.297574 1069.92028 426.674131 1069.90505 426.663606 999.744278 402.242098 991.390896 393.886658 939.740829 383.370203 871.422213 360.79949 871.335534 361.062623 939.669934 383.637491 991.254092 394.141481 999.593627 402.482796 1069.77932 426.91289 1097.27702 445.762626 1097.62236 446 1081.72036 399.843711 1077.37972 392.705329 1064.9499 383.058875 1012.97638 377.489597 1012.92265 377.484058 1002.18433 385.462249 996.403369 375.419987 993.950026 349.962844 1016.96032 353.338701 1017.00463 353.344795 1025.97197 348.705345 1087.75776 355.194204 1087.91589 355.210823 1087.29805 338.207725 1107.89183 340.667049 1123.67198 332.311609 1142.90049 330.450566 1169.19991 250.919878 1184.95569 226.509173 1185 226.440758 1182.12046 221"/>
                    <polygon id="Fill-28" points="862.264042 450 862 449.915013 878.735958 398 879 398.084987"/>
                    <polygon id="Fill-29" points="868.25719 520 868 519.890163 880.598359 492 880.732288 492.070219 908 506.405059 907.866632 506.645225 880.73285 492.380878"/>
                    <polygon id="Fill-30" points="918.942286 478 907 475.296124 907.057714 475 919 477.703573"/>
                    <polygon id="Fill-32" points="1138.72555 239 1132.25579 188.612592 994.088665 188.509329 994.088112 188.371367 993.882274 129.806464 991 67.0127691 991.27611 67 994.158661 129.799524 994.364221 188.232018 1132.49897 188.335281 1132.51474 188.45631 1139 238.964469"/>
                    <polygon id="Fill-33" points="1118.99447 248 939.822445 244.301228 790 244.301228 790 244 939.824936 244 1119 247.698772"/>
                    <polygon id="Fill-34" points="878.782774 244 877.166178 192.796164 877.166178 129.556932 877 69.0011053 877.217226 69 877.383404 129.556379 877.383404 192.790638 879 243.988947"/>
                    <polygon id="Fill-35" points="826 70 838.397862 46.3759799 868.210037 3 868.436844 3.15556403 838.632926 46.5175267 826.458568 69.7188304 934.996146 68.1879264 935 68.4627745"/>
                    <polygon id="Fill-36" points="757.74932 0 750.042252 14.5967086 750 14.676546 758.309095 25.0664588 776.013975 28.4641067 807.299012 44.41888 789.460471 70 826 69.7928095 825.998343 69.5165555 789.991802 69.7207072 807.7069 44.3169423 776.12223 28.2094005 776.104556 28.2002841 758.46126 24.8142389 750.328906 14.6456056 757.993445 0.12928687"/>
                    <polygon id="Fill-37" points="596.714055 170 594.663911 159.109168 588 148.13864 588.251364 148 594.940848 159.012591 594.945497 159.036389 597 169.951019"/>
                    <polygon id="Fill-38" points="637.736371 179 637.5992 178.988816 491.583557 167.117148 491.607064 166.967568 497.91363 127.159273 401.833356 113.305312 397.24724 122.578192 397 122.452936 401.675167 113 401.77362 113.014259 498.231392 126.92274 498.208991 127.063094 491.904084 166.862442 637.484153 178.699161 640.703533 140.108481 610.162153 134.617055 610.210827 134.341659 641 139.877819 640.989491 140.005033"/>
                    <polygon id="Fill-39" points="789.724214 233 789.416437 192.159482 788.030888 107.076754 789.513789 69.3496711 688.956426 59.2488152 688.921125 59.1881153 684.395753 51.4562195 667.606178 69.0974476 654.993657 96.7671905 641.337562 138.869103 689.406784 161.712232 689.288748 161.96307 641 139.015448 641.037507 138.899315 654.737176 96.6665783 667.374517 68.9394615 667.390789 68.9225542 684.448704 51 689.123552 58.9868908 789.799779 69.0999421 788.306674 107.079803 789.692223 192.156156 790 232.997783"/>
                    <polygon id="Fill-40" points="626.283537 245 626 244.95352 637.716463 178 638 178.04648"/>
                    <polygon id="Fill-41" points="634.00443 196 634 195.698772 704.162276 194.521877 789.468163 192 821 192.33617 820.997231 192.637397 789.470655 192.301228 704.168091 194.823104"/>
                    <polygon id="Fill-42" points="702.21302 234 702 233.983304 703.78698 195 704 195.016418"/>
                    <polygon id="Fill-43" points="694.726781 120 682 70.3188058 683.729638 52 684.009892 52.0253418 682.284474 70.2973204 695 119.933065"/>
                    <polygon id="Fill-44" points="787.999445 108 723.031942 107.844953 723.003375 107.744546 710 62.0759982 710.266541 62 723.241625 107.568142 788 107.722634"/>
                    <polygon id="Fill-45" points="749.719888 193 748 108.005517 748.280112 108 750 192.994483"/>
                    <polygon id="Fill-46" points="1087.14798 337 999.690559 328.574124 999.65744 328.545891 991.070503 321.235918 955.042794 321.541551 945 316.477911 945.125024 316.233405 955.107376 321.267166 991.171516 320.960711 991.210707 320.994152 999.803716 328.309608 1087.02986 336.713007 1096.67578 325.308374 1107.74885 301 1108 301.112933 1096.91147 325.455571 1096.89933 325.470099"/>
                </g>
            </g>
        </g>
    </g>` : ''}
    
    ${cursors ? `
            <g mask="url(#mask-2)" class="">
                <g transform="translate(256.000000, 558.000000)" id="PlanPointFalse">
                    <circle class="PlanPoint" style="transform-box: fill-box;" stroke="#34DF77" stroke-width="2" fill-opacity="0.2" fill="#34DF77" cx="26" cy="26" r="13"/>
                    <circle class="PlanPointSmall" style="transform-box: fill-box; transform-origin: center center" stroke="none" fill="#FFFFFF" cx="16" cy="16" r="3"/>
                </g>
            </g>
            <g mask="url(#mask-2)" class="">
                <g transform="translate(642.000000, 164.000000)" id="PlanPointTrue">
                    <circle class="PlanPoint" style="transform-box: fill-box;" stroke="#34DF77" stroke-width="2" fill-opacity="0.2" fill="#34DF77" cx="26" cy="26" r="13"/>
                    <circle class="PlanPointSmall" style="transform-box: fill-box; transform-origin: 50% 50%" stroke="none" fill="#FFFFFF" cx="16" cy="16" r="3"/>
                </g>
            </g>` : ''}

    ${cursors ? `<polyline class="PlanPath" fill="transparent" stroke="#34DF77" stroke-width="2" mask="url(#mask-2)" transform="translate(462.510691, 374.567862) rotate(-14.000000) translate(-462.510691, -374.567862) " points="239.875972 515.584631 469.799186 457.173246 456.953922 358.720065 505.416743 360.349914 508.551488 342.341245 544.326875 342.341245 544.326875 308.55018 601.112827 303.508845 618.377881 297.949175 604.694076 260.910643 685.145411 233.551092"/>` : null}

    ${cursors ? `
                <g id="Question2-True" class="PlanButton" mask="url(#mask-2)">
                    <g transform="translate(654.406250, 72.000000)">
                        <g id="Group-2" stroke="none" stroke-width="1" fill-rule="evenodd" transform="translate(0.593750, 1.000000)">
                            <rect id="Rectangle" stroke="#34DF77" stroke-width="2" fill="#34DF77" x="0" y="0" width="99" height="45"/>
                            <text id="vrai" font-size="30" font-weight="bold" fill="#1A1A1A">
                                <tspan x="25.3300781" y="33">vrai</tspan>
                            </text>
                        </g>
                        <path d="M0.5,0 L0.5,106" id="Path-7" stroke="#34DF77" stroke-width="2"/>
                    </g>
                </g>
                <g id="Question2-False" class="PlanButton" mask="url(#mask-2)">
                    <g transform="translate(171.000000, 463.000000)">
                        <g id="Group-2" stroke="none" stroke-width="1" fill-rule="evenodd" transform="translate(0.593750, 1.000000)">
                            <rect id="Rectangle" fill="#34DF77" x="0" y="0" width="99" height="45"/>
                            <text id="faux" font-size="30" font-weight="bold" fill="#1A1A1A">
                                <tspan x="21.1479492" y="33">faux</tspan>
                            </text>
                        </g>
                        <path d="M98.5,0 L98.5,106" id="Path-7" stroke="#34DF77" stroke-width="2"/>
                    </g>
                </g>` : null
        }
</svg>`;

    return <i className={cursors ? 'Cursors' : ''} dangerouslySetInnerHTML={{__html: svg}}/>
}
