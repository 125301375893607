import React from "react";
import {Facebook, Instagram, Twitter} from "../components/Pictos/Socials";

import styles from "./Social.module.scss";
import Info from "../components/Pictos/Info";

export default function Social () {
    return (
        <nav className={styles.Main}>
            <ul>
                <li>
                    <a href="https://facebook.com/traderepublic.fr" target={'_blank'} title={'Rejoignez-nous sur Facebook'}>
                        <Facebook/>
                    </a>
                </li>

                <li>
                    <a href="https://twitter.com/TraderepublicFR" target={'_blank'} title={'Rejoignez-nous sur Twitter'}>
                        <Twitter/>
                    </a>
                </li>

                <li>
                    <a href="https://www.instagram.com/traderepublic.fr" target={'_blank'} title={'Suivez-nous sur Instagram'}>
                        <Instagram/>
                    </a>
                </li>

                <li>
                    <a href="https://traderepublic.com/fr-fr/mentions-legales" target={'_blank'} title={"Mentions légales"}>
                        <Info/>
                    </a>
                </li>
            </ul>
        </nav>
    )
}
