import React from "react";
import styles from "./Content.module.scss"

export default function Content ({className, children, container = true}) {
    return (
        <section className={className}>
            {
                container ?
                    <div className={styles.Container}>
                        {children}
                    </div>
                    : children
            }
        </section>
    )
}
