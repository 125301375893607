import React, {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

import QuestionTitle from "../../../components/Questions/QuestionTitle";
import styles from "./QuestionTwo.module.scss"

import QuestionsMaster from "../QuestionsMaster";
import Plan from "./Plan";

import glaussian from "../../../images/glaussian.png";
import Answer from "../../../components/Questions/Answer";
import {hideQuestion} from "../../../functions/Animations";
import answers from "../../../functions/Answers";

export default function QuestionTwo() {
    const [correct, setCorrect] = useState(null);
    const dispatch = useDispatch();
    const {userAnswers} = useSelector(state => state)

    const plan = useRef(null);
    const planCursor = useRef(null);

    useEffect(() => {
        const trueButton = document.querySelector('#Question2-True');
        const falseButton = document.querySelector('#Question2-False');

        trueButton.addEventListener('click', () => {
            hideQuestion(
                [document.querySelector(`.${styles.TitleBox}`), document.querySelector(`.${styles.Plan}`), document.querySelector(`.${styles.PlanCursor}`)],
                () => {
                    setCorrect(false);
                    setTimeout(() => {
                        answers.validate('true', 1, 0, userAnswers, false, dispatch);
                    }, 1000);
                })
        });

        falseButton.addEventListener('click', () => {
            hideQuestion(
                [document.querySelector(`.${styles.TitleBox}`), document.querySelector(`.${styles.Plan}`), document.querySelector(`.${styles.PlanCursor}`)],
                () => {
                    setCorrect(true);
                    setTimeout(() => {
                        answers.validate('false', 1, 0, userAnswers, true, dispatch);
                    }, 1000);
                })
        });
    }, []);

    return (
        <QuestionsMaster className={styles.Main} questionIndex={1}>
            <QuestionTitle type={"Vrai ou faux ?"} advice={"Choisissez la bonne réponse !"} className={styles.TitleBox}>
                Investir en bourse, <br/>
                c’est une course de
                vitesse pas d’endurance ?
            </QuestionTitle>

            <div className={styles.Plan} ref={plan}>
                <Plan/>
                <img className={styles.Glaussian} src={glaussian} alt=''/>
            </div>

            <div className={styles.PlanCursor} ref={planCursor}>
                <Plan cursors={true}/>
            </div>

            {
                correct !== null ?
                    <Answer correct={correct} questionIndex={2}>
                        <h4>
                            La patience est une des qualités de base de l'investisseur gagnant !
                        </h4>

                        <p>
                            Quelques proverbes bien connus des boursiers résument souvent l'état d'esprit pratique des
                            investisseurs qui revendiquent le bon sens : "Les arbres ne montent pas au ciel" est un des
                            proverbes les plus connus qui signifie qu'il ne faut pas oublier de prendre ses bénéficies
                            quand le marché boursier progresse fortement, sans vouloir gagner toujours plus !
                            <br/><br/>
                            "Acheter au son du canon vendre à celui du violon" est aussi un adage important en bourse
                            qui rappelle que les périodes difficiles offrent souvent des opportunités de placement très
                            intéressantes en bravant un peu la peur du court terme dans un contexte financier parfois
                            perturbé... La Bourse est souvent une affaire de psychologie et d'anticipations...
                            Inversement, quand tous les investisseurs sont positifs, alors la hausse est bientôt
                            terminée !
                            <br/><br/>
                            Avoir un bon tuyau, c'est le rêve de tout investisseur : Comme le dit le proverbe "il faut
                            acheter la rumeur et vendre la nouvelle"… Pourtant, les rumeurs ne se vérifient pas toujours
                            en Bourse. Alors, méfiez-vous, car vous risquez d'être au final le dernier maillon de la
                            chaîne, celui qui achète au plus haut et qui voit ensuite le cours s'effondrer !
                        </p>
                    </Answer>
                    : null
            }
        </QuestionsMaster>
    )
}
