import React from "react";
import buttonStyles from "./Buttons.module.scss";
import DelayLink from "../DelayLink";
import styles from "./NextButton.module.scss";

export default function Button({url = "/", label = 'Cliquez-ici', external = false, delayStart = () => {}, delay, classname = null}) {
    return (
        external ?
            <a href={url} className={[buttonStyles.Global, styles.Main, classname ?? ''].join(' ')} target={external ? '_blank' : '_self'} title={label}>
                <span>{label}</span>
            </a>
        :
            <DelayLink to={url} className={[buttonStyles.Global, styles.Main, classname ?? ''].join(' ')} label={label} onDelayStart={delayStart} delay={delay}>
                <span>{label}</span>
            </DelayLink>
    )
}

