import React from "react";

export default function Chevron () {
    const svg = `
    <svg width="23px" height="15px" viewBox="0 0 23 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path d="M18.3253125,6.79 L7.7190625,-3.81625 C7.4740625,-4.06125 7.0790625,-4.06125 6.8353125,-3.81625 L4.1828125,-1.165 C3.9390625,-0.92125 3.9390625,-0.525 4.1828125,-0.28125 L11.6953125,7.2325 L4.1828125,14.745 C3.9390625,14.99 3.9390625,15.385 4.1828125,15.62875 L6.8353125,18.28 C7.0790625,18.525 7.4740625,18.525 7.7190625,18.28 L18.3253125,7.67375 C18.5728125,7.42625 18.5728125,7.0375 18.3253125,6.79" id="Fill-1" fill="#48E284" transform="translate(11.255469, 7.231875) rotate(90.000000) translate(-11.255469, -7.231875) "/>
            </g>
        </svg>`
    return (
        <i dangerouslySetInnerHTML={{__html: svg}}/>
    )
}
