import React, {useEffect, useRef} from "react";
import gsap from "gsap";

import styles from "./QuestionTitle.module.scss"

export default function QuestionTitle ({children, type = null, advice = null, source = null, className}) {
    const typeRef = useRef(null);
    const titleRef = useRef(null);
    const sourceRef = useRef(null);

    useEffect(() => {
        gsap.to(titleRef.current, .4, {
            opacity: 1,
            delay: 1,
            onComplete: () => {
                gsap.to(typeRef.current, .4, {
                    opacity: 1,
                    onComplete: () => {
                        gsap.to(sourceRef.current, .4, {
                            opacity: 1
                        })
                    }
                })
            }
        })
    }, []);

    return (
        <div className={className}>
            {
                type ? <h3 className={styles.Type} ref={typeRef}>{type}</h3> : null
            }
            <h2 className={styles.Title} ref={titleRef}>
                {children}

                {
                    advice ? <small> {advice} </small> : null
                }
            </h2>

            {
                source ?
                    <span className={styles.Source} ref={sourceRef}>
                        {source}
                    </span>
                : null
            }
        </div>
    )
}
