import React, {useEffect, useState, useRef} from "react";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useDispatch, useSelector} from "react-redux";
import gsap, {Power1} from "gsap";
import QuestionTitle from "../../../components/Questions/QuestionTitle";
import styles from "./QuestionThree.module.scss";
import styleButton from "../../../components/Buttons/Buttons.module.scss";


import QuestionsMaster from "../QuestionsMaster";

import Answer from "../../../components/Questions/Answer";
import {hideQuestion} from "../../../functions/Animations";
import Validate from "../../../components/Buttons/Validate";
import answers from "../../../functions/Answers";
import isMobile from "ismobilejs";


export default function QuestionThree() {
    const {userAnswers} = useSelector(state => state);
    const dispatch = useDispatch();

    const [showNext, setShowNext] = useState(false);
    const [correct, setCorrect] = useState(null);
    const [boxes, setBoxes] = useState([
        {
            label: "1 000",
            coin: null
        },
        {
            label: "5 000",
            coin: null
        },
        {
            label: "10 000",
            coin: null
        }
    ]);
    const [frais, setFrais] = useState(["1", "1", "8", "27", "57"]);

    const bubbleAnimation = useRef();

    useEffect(() => {
        const DropBoxes = document.getElementsByClassName(styles.DropBoxWrapper);
        const Coins = document.getElementsByClassName(styles.Coin);
        const CoinsBlocks = document.getElementsByClassName(styles.CoinBlock);

        bubbleAnimation.current = gsap.timeline({repeat: -1});

        Object.values(DropBoxes).map((box, index) => {
            gsap.to(box, .3, {
                opacity: 1,
                scale: 1,
                delay: .2 * index,
                onComplete: () => box.classList.add('show')
            })
        });


        if (!isMobile().phone) {
            bubbleAnimation.current.to(CoinsBlocks,{
                x: "random(-20, 20, 5)", //chooses a random number between -20 and 20 for each target, rounding to the closest 5!
                y: "random(-20, 10, 3)",
                duration:4,
                ease:Power1.easeInOut,
                repeat:-1,
                repeatRefresh:true // gets a new random x and y value on each repeat
            });
        }

        Object.values(Coins).map((box, index) => {
            gsap.to(box, .3, {
                opacity: 1,
                //scale: 1,
                delay: .2 * index,
                onComplete: () =>
                    box.classList.add('show')
            });
        })
    }, []);

    useEffect(() => {
        if (userAnswers) {
            if (userAnswers[2]) {
                setBoxes(() => userAnswers[2][0]);

                let fraisClone = [...frais];

                userAnswers[2][0].map(item => {
                    const index = fraisClone.indexOf(item.coin);
                    fraisClone.splice(index, 1);
                });

                setFrais(fraisClone);
            }

            if (boxes.length === 3) {
                setShowNext(true);
            }
        }
    }, [userAnswers]);

    useEffect(() => {
        document.querySelector(`.${styles.Coin}:not(.show)`)?.classList.add('show');
    }, [frais]);

    const handleOnDragEnd = (result) => {
        const {source, destination} = result;
        const boxesState = [...boxes];
        const fraisState = [...frais];

        bubbleAnimation.current.paused(false)

        let destIndex, sourceIndex, destDrop, sourceDrop;

        if (!destination) return;
        else {
            destIndex = !isNaN(parseFloat(destination.droppableId.replace(/[a-zA-Z]+/g, ''))) ? parseFloat(destination.droppableId.replace(/[a-zA-Z]+/g, '')) : destination.index;
            sourceIndex = !isNaN(parseFloat(source.droppableId.replace(/[a-zA-Z]+/g, ''))) ? parseFloat(source.droppableId.replace(/[a-zA-Z]+/g, '')) : source.index;
            destDrop = destination.droppableId.replace(/[0-9]+/g, '')
            sourceDrop = source.droppableId.replace(/[0-9]+/g, '')
        }

        let tempDest, tempSource;

        if (destDrop !== sourceDrop) {
            if (destDrop === 'dropfrais') {
                tempSource = boxesState[sourceIndex].coin;

                boxesState[sourceIndex].coin = null;
                fraisState[destIndex] = tempSource;
            } else {
                tempSource = boxesState[destIndex].coin;
                boxesState[destIndex].coin = fraisState[sourceIndex];
                fraisState[sourceIndex] = tempSource;
            }
        } else {
            if (destDrop === 'dropInvest') {
                tempDest = boxesState[destIndex].coin;
                tempSource = boxesState[sourceIndex].coin;

                boxesState[destIndex].coin = tempSource;
                boxesState[sourceIndex].coin = tempDest;
            }
        }

        setFrais(() => fraisState);
        setBoxes(() => boxesState);

        if (boxesState.findIndex(item => item.coin === null) > -1) {
            setShowNext(false);
        } else {
            setShowNext(true);
        }
    };

    const ValidateAnswer = async () => {
        const parsedAnswers = userAnswers;

        hideQuestion(
            [document.querySelector(`.${styles.TitleBox}`), document.querySelector(`.${styleButton.Validate}`)],
            () => {
                setTimeout(() => {
                    const correct = boxes[0].coin === '8' && boxes[1].coin === '27' && boxes[2].coin === '57';
                    setCorrect(correct);

                    answers.validate(boxes, 2, 0, parsedAnswers, correct, dispatch)
                }, 1000);
            }
        );
    };

    return (
        <QuestionsMaster className={styles.Main} questionIndex={2}>
            <QuestionTitle type={"Le saviez-vous ?"}
                           advice={`${isMobile().phone ? 'Maintenir' : 'Cliquez'}, puis associez les frais aux montants d'investissement.`}
                           source={'Source : La lettre de l’observatoire de l’épargne de l’AMF, n°42, mars 2021. Frais moyen pour les ordres sur Euronext Paris.'}>
                Quels frais pour passer un <br/>
                ordre en bourse avec une <br/>
                banque traditionnelle ?
            </QuestionTitle>

            <div>
                <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={(e) => {
                    document.querySelector(`[data-rbd-droppable-id=${e.source.droppableId}]`).closest(`.${styles.CoinBlock}`)?.removeAttribute('style')
                    bubbleAnimation.current.paused(true);
                }}>
                    {
                        boxes.length > 0 ?
                            boxes.map((box, index) =>
                                <div className={styles.DropBoxWrapper} key={index}>
                                    <div className={styles.DropBox}>
                                        <Droppable droppableId={`dropInvest${index}`} key={index}>
                                            {provided => (
                                                <>
                                                    <div className={styles.DropHole} {...provided.droppableProps}
                                                         ref={provided.innerRef}>

                                                        {
                                                            box.coin ?
                                                                <Draggable key={index}
                                                                           draggableId={`invest${index.toString()}`}
                                                                           index={index}>
                                                                    {(provided) => (
                                                                        <div key={index}
                                                                             className={styles.Coin}
                                                                             ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                            {box.coin}€
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                                : null
                                                        }
                                                    </div>
                                                    <span>Pour <strong>{box.label}€</strong><br/>investis</span>
                                                    {provided.placeholder}
                                                </>
                                            )}
                                        </Droppable>
                                    </div>
                                </div>
                            ) : null
                    }

                    <div className={styles.CoinsWrapper}>
                        {
                            frais.map((item, index) =>
                                <div className={styles.CoinBlock} key={index}>
                                    <Droppable droppableId={`dropfrais${index}`} isDropDisabled={true} direction={'horizontal'}>
                                        {provided => (
                                            <>
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    <Draggable key={index} draggableId={`frais${index.toString()}`}
                                                               index={index}>
                                                        {(provided) => (
                                                            <span key={index}
                                                                  className={item ? styles.Coin : ''}
                                                                  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            {
                                                                item ? `${item}€` : null
                                                            }
                                                        </span>
                                                        )}
                                                    </Draggable>

                                                </div>
                                                {provided.placeholder}
                                            </>
                                        )}
                                    </Droppable>
                                </div>
                            )
                        }
                    </div>
                </DragDropContext>
            </div>


            <Validate {...{showNext, ValidateAnswer}} classname={styles.Validate}/>

            {
                correct !== null ?
                    <Answer correct={correct} questionIndex={3}>
                        <h4>
                            Les frais sont proportionnels aux montants investis <span className={correct ? 'text--green' : 'text--orange'}>(sauf chez Trade Republic !)</span>
                        </h4>

                        <p>
                            Selon la lettre de l’observatoire de l’épargne de l’AMF n°42 (mars 2021), les frais moyens pour les ordres sur Euronext Paris seraient variables si vous passez par votre banque ou par un courtier (cf tableau ci-dessous).
                            <br/><br/>
                            Chez Trade Republic, c'est simple, les frais sont toujours de 1€ quel que soit le montant investi !
                            <br/><br/>

                            <table className={'AnswerTable'}>
                                <thead>
                                <tr>
                                    <th/>
                                    <th>
                                        <span>1000€</span>
                                        <br/>
                                        investis
                                    </th>
                                    <th>
                                        <span>5000€</span>
                                        <br/>
                                        investis
                                    </th>
                                    <th>
                                        <span>10000€</span>
                                        <br/>
                                        investis
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr>
                                    <td>Banques</td>
                                    <td>8€</td>
                                    <td>27€</td>
                                    <td>52€</td>
                                </tr>
                                <tr>
                                    <td>Courtiers en ligne</td>
                                    <td>3€</td>
                                    <td>10€</td>
                                    <td>16€</td>
                                </tr>
                                <tr>
                                    <td>Trade Republic</td>
                                    <td>1€</td>
                                    <td>1€</td>
                                    <td>1€</td>
                                </tr>
                                </tbody>
                            </table>
                        </p>
                    </Answer>
                    : null
            }
        </QuestionsMaster>
    )
}
