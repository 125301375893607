import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Master from "./partials/Master";

import Home from "./pages/Home";
import QuestionOne from "./pages/Questions/1/QuestionOne";
import QuestionTwo from "./pages/Questions/2/QuestionTwo";
import QuestionThree from "./pages/Questions/3/QuestionThree";
import QuestionFour from "./pages/Questions/4/QuestionFour";
import Continued from "./pages/Continued";
import Conclusion from "./pages/Conclusion";
import {useDispatch, useSelector} from "react-redux";

export default function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        const answers = JSON.parse(localStorage.getItem('answers'));

        dispatch({type: 'UPDATE_USER_ANSWERS', payload: answers});
    }, []);

    return (
        <Master>
            <Router basename={process.env.PUBLIC_URL}>
                <Route
                    render={({location}) => (
                        <Switch location={location}>
                            <Route exact path="/" component={Home}/>
                            <Route exact path="/question-1" component={QuestionOne}/>
                            <Route exact path="/question-2" component={QuestionTwo}/>
                            <Route exact path="/question-3" component={QuestionThree}/>
                            <Route exact path="/question-4" component={QuestionFour}/>
                            <Route exact path="/conclusion" component={Conclusion}/>
                        </Switch>
                    )}
                />
            </Router>
        </Master>
    );
}
