import React, {useEffect} from "react";
import styleButton from "./Buttons.module.scss";
import gsap from "gsap/gsap-core";
import isMobile from "ismobilejs";

export default function Validate({showNext = false, ValidateAnswer = () => {}, classname = null}) {
    useEffect(() => {
        const button = document.querySelector(`.${styleButton.Validate}`);

        if (button) {
            let {width, x: buttonX, y: buttonY} = button.getBoundingClientRect(); // gives you width, height, left-X,top-y of the button

            let distance = 100;
            let mouseHasEntered = true;
            let mouseIsInButtonTerritory;

            const catchCursor = (xWalk, yWalk) => {
                gsap.set(button, {
                    x: xWalk,
                    y: yWalk,
                    scale: 1.2
                })
            };

            const resetPositon = () => {
                // resets the postion of the button as it was initial.
                gsap.to(button, .2, {
                    x: 0, y: 0, scale: 1
                });

                if (!mouseHasEntered) distance /= 2;
                mouseHasEntered = true;
                // when button is return to it's position (mouseHasEntered = true) lets to increase the initial borderline of button for the next time
            };

            const mouseMove = (e) => {
                const x = e.x; // current x of cursor
                const y = e.y; // current y of cursor

                const leftBorderLine = buttonX - distance;
                const rightBorderLine = buttonX + width + distance;
                const topBorderLine = buttonY - distance;
                const bottomBorderline = buttonY + width + distance;

                const xWalk = (x - buttonX) / 2; // the distance to move the button when mouse moves on X axis
                const yWalk = (y - buttonY) / 2; // the distance to move the button when mouse moves on Y axis

                mouseIsInButtonTerritory =
                    x > leftBorderLine &&
                    x < rightBorderLine &&
                    y > topBorderLine &&
                    y < bottomBorderline;

                if (mouseIsInButtonTerritory) {
                    if (mouseHasEntered) {
                        // this must happen only once to create outside borderline
                        //creating another level borderline by incresing distance;
                        // while cursor is returing the button comes out of nearest border-line and return from this borderline
                        distance = distance + distance;
                        mouseHasEntered = false;
                    }
                    catchCursor(xWalk, yWalk); // call the function when mouse in in the button's territory
                } else {
                    resetPositon();
                }
            };

            if (!isMobile().phone) window.addEventListener('mousemove', e => mouseMove(e));
        }
    }, []);

    return (
        <button className={[styleButton.Validate, classname ?? ''].join(' ')}
                style={{transform: `scale(${showNext ? 1 : .8})`, visibility: !showNext ? 'hidden' : 'visible'}}
                onClick={ValidateAnswer}>
            Valider
        </button>
    )
}
